import React from 'react'
import Layout from '../components/layout'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import styles from '../components/section.module.scss'
import ReactMarkdown from "react-markdown"

const query = graphql`
query {
    strapiImpressum {
      inhalt
      Titel
    }
  }
`

const Impressum = () => {
    const data = useStaticQuery(query);
    console.log(data);
    return (
        <Layout>
            <Container>
                <Row className="mb-5 mt-5">
                    <Col md="2"></Col>
                    <Col md="8">
                        <h1 className="text-center">{data.strapiImpressum.Titel}</h1>
                        <h2 className={styles.separator}></h2>
                        <ReactMarkdown source={data.strapiImpressum.inhalt}></ReactMarkdown>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Impressum